import React from "react"
import PageNews from "../../components/aktuellt/PageNews"
import { useStaticQuery, graphql } from "gatsby"
import Layout from "../../components/layout"

import SEO from "../../components/seo"

export default function AktuelltPage() {
  const data = useStaticQuery(graphql`
    {
      wpPage(databaseId: { eq: 315 }) {
        id
        title
        featuredImage {
          node {
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
        }
      }
    }
  `)
  const page = data.wpPage
  return (
    <Layout>
      <SEO title="Aktuellt" />
      <PageNews page={page} />
    </Layout>
  )
}
