import React from "react"
import FeaturedImagePage from "../common/FeaturedImagePage"
import H1 from "../layout/H1"

import PageNewsItems from "./PageNewsItems"
export default function PageNews({ page }) {
  const { title, featuredImage } = page
  const image = featuredImage?.node.localFile.childImageSharp
  return (
    <div className="min-h-screen  flex flex-col items-center justify-start">
      <div className=" w-full relative bg-gradient-to-t bg-black">
        {image && <FeaturedImagePage image={image} />}
        <div className="absolute top-0 w-full h-full flex flex-col justify-center ">
          <div className="px-24 max-w-screen-2xl mx-auto w-full">
            <H1 text={title} />
          </div>
        </div>
      </div>
      <div className=" relative px-4 md:px-24 max-w-4xl w-full space-y-8 py-8 md:py-16">
        <div className="page-content flex flex-col md:flex-row gap-16 ">
          <div className="w-full pt-4 md:pt-8 flex-none">
            <PageNewsItems context="pageFeed" />
          </div>
          {/* <div className="w-1/3">
            <div className="bg-secondary/20 p-8"></div>
          </div> */}
        </div>
      </div>
    </div>
  )
}
