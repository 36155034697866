import React from "react"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

export default function FeaturedImagePage({ image }) {
  return (
    <div>
      <GatsbyImage
        image={getImage(image)}
        className="h-screen/3 md:h-screen/2 opacity-60 w-full "
        alt="Utvald bild"
      />
    </div>
  )
}
